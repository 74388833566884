import { noop } from '@vueuse/core';
import { Mesh } from 'three';
import { computed, defineComponent, inject, onMounted, provide, reactive, watch } from 'vue';

import { simpleMaterial, simplePlaneGeometry } from '@resn/gozer-three';
import { useThreeObject, useViewportResize } from '@resn/gozer-vue';

import { LAYER_FG } from '~/core/constants';
import { useFanatics as useFanaticsIR } from '~/providers/instant-rips/FanaticsProvider';
import { useFanatics as useFanaticsWE } from '~/providers/white-elephant/FanaticsProvider';

export const useBounds = (cb = noop) => {
    let bounds = inject('bounds', null);

    if (!bounds) {
        const viewport = useViewportResize();
        bounds = reactive({
            x: 0,
            y: 0,
            width: computed(() => viewport.width),
            height: computed(() => viewport.height),
        });
    }

    watch(bounds, cb);

    onMounted(() => cb(bounds));

    return bounds;
};

export const Bounds = defineComponent({
    props: {
        data: Object,
        type: { type: String, default: 'instant-rips' },
    },

    setup(props) {
        const bounds = reactive({ x: 0, y: 0, width: 1, height: 1 });

        const { object } = useThreeObject(null, { name: 'Bounds' });
        const { boundsAdjust = { width: 0, height: 0 } } =
            props.type === 'white-elephant' ? useFanaticsWE() : useFanaticsIR();

        const hasDebug = false;

        let meshDebug = null;
        if (hasDebug) {
            meshDebug = new Mesh(simplePlaneGeometry, simpleMaterial('red', true));
            meshDebug.layers.set(LAYER_FG);

            meshDebug.material.depthTest = false;
            meshDebug.material.depthWrite = false;

            meshDebug.material.opacity = 0.3;
            meshDebug.renderOrder = 10;

            object.add(meshDebug);
        }

        provide('bounds', bounds);

        useViewportResize(({ width, height }) => {
            bounds.width = width - boundsAdjust.width;
            bounds.height = height - boundsAdjust.height;

            bounds.x = (width - bounds.width) * 0.5;
            bounds.y = (height - bounds.height) * 0.5;

            object.position.set(bounds.x, bounds.y, 0);

            // ― debug
            if (hasDebug) {
                meshDebug.scale.set(10, 10, 1);
                // meshDebug.scale.set(bounds.width, bounds.height, 1);
            }
        }, true);
    },

    render() {
        if (this.$slots.default) {
            return this.$slots.default();
        }
        return null;
    },
});
